<template>
    <div>
        <app-header :title="title" :isShowBack="true"></app-header>
        <div>
            <div class="baseInfo-title">网关信息</div>
            <div class="baseInfo-content">
                <div class="content-left">
                    <div class="content-box">
                        <div class="title">网关名称</div>
                        <div class="content">{{name}}</div>
                    </div>
                    <div class="content-box">
                        <div class="title">网关型号</div>
                        <div class="content">{{innerTypeStr}}</div>
                    </div>
                    <div class="content-box">
                        <div class="title">网关MAC</div>
                        <div class="content">{{mac}}</div>
                    </div>
                    <div class="content-box" v-if="!showUnlockList">
                        <div class="title">网关WiFi名称</div>
                        <div class="content">{{wifiName}}</div>
                    </div>
                    <div class="content-box">
                        <div class="title">关联门锁（把）</div>
                        <div class="content">{{lockNum}}</div>
                    </div>
                    <div class="content-box" v-if="showUnlockList">
                        <div class="title">绑定人</div>
                        <div class="content">{{bindUser}}</div>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-box" v-if="!showUnlockList">
                        <div class="title">网关绑定的房源</div>
                        <div class="content">{{houseName}}</div>
                    </div>
                    <div class="content-box" v-if="showUnlockList">
                        <div class="title">房源/楼栋</div>
                        <div class="content">{{houseName}}</div>
                    </div>
                    <div class="content-box" v-if="showUnlockList">
                        <div class="title">网关ICCID</div>
                        <div class="content">{{iccid}}</div>
                    </div>
                    <div class="content-box">
                        <div class="title">安装位置</div>
                        <div class="content">{{location}}</div>
                    </div>
                    <div class="content-box">
                        <div class="title">网关状态</div>
                        <div class="content">{{onLineStr}}</div>

                    </div>
                    <div class="content-box">
                        <div class="title">绑定时间</div>
                        <div class="content">{{bindTime}}</div>
                    </div>
                </div>
            </div>
            <div class="beRelated-row">
                <div class="baseInfo-title">关联的门锁</div>
                <div class="beRelated-refresh">
                    <el-button icon="el-icon-refresh" circle @click="onRefresh"></el-button>
                </div>
            </div>
            <el-table :data="lockList"  style='width:100%'>
                <el-table-column v-if="!showUnlockList" prop="lockName" align="center" label="锁名称">
                </el-table-column>
                <el-table-column v-if="showUnlockList" prop="mac" align="center" label="锁MAC">
                </el-table-column>
                <el-table-column prop="lockType" align="center" label="门锁型号">
                </el-table-column>
                <el-table-column prop="roomNo" align="center" label="门锁绑定的房号">
                </el-table-column>
                <el-table-column prop="batteryPower" align="center" label="门锁电量（%）">
                </el-table-column>
                <el-table-column prop="rssiName" align="center" label="信号强度（-db）">
                </el-table-column>
                <el-table-column prop="lastUseDate" align="center" label="最近一次使用时间（网关与锁）">
                </el-table-column>
            </el-table>
            <div class="logList" v-if="showUnlockList">
                <app-list :opt="opt" @get="onGet"></app-list>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                title: '网关详情',
                name: '',
                mac: '',
                wifiName: '',
                lockNum: '',
                houseName: '',
                location: '',
                onLineStr: '',
                innerTypeStr: '',
                bindTime: '',
                lockList: [],
                logList: [],
                macList:[],
                gatewayInfo: '',
                showUnlockList: true,
                bindUser: '',
                iccid: '',
                unlockStateStr: '',
                showrssiName: '',
                opt: {
                    title: "远程操作日志",
                    columns: [{
                            label: "操作类型",
                            key: "unlockMethodStr",
                        },
                        {
                            label: "远程分组名称",
                            key: "strategyName"
                        },
                        {
                            label: "操作人",
                            key: "unlockUser"
                        },
                        {
                            label: "操作人角色",
                            key: "unlockRoleStr"
                        },
                        {
                            label: "操作人账号",
                            key: "accountName"
                        },
                        {
                            label: "操作结果",
                            key: "unlockStateStr"
                        },
                        {
                            label: "操作时间",
                            key: "unlockDate"
                        },
                    ]
                }
            }
        },
        activated() {
            this.lockList = []
            this.logList = []
            this.macList = []
            console.log(11)
            this.gatewayInfo = this.cache.getLS('gatewayRowData');
            this.showUnlockList = this.gatewayInfo.innerType == 27 ? true : false;
            
            this.getDetail()
        },
        methods: {
            onGet(opt) {
                this.gatewayInfo = this.cache.getLS('gatewayRowData');
                this.getDetail().then(res => {
                    let dto = {
                        gatewayId: this.$route.query.deviceId,
                        strategyId: "",
                        pageNo: opt.skip,
                        pageSize: opt.limit,
                        macList: this.macList
                    };
                    this.post("device-service/v1/unlock-record/query/unlock-record-list", dto, {
                        isUseResponse: true
                    }).then(res => {
                        let obj = {
                            data: [],
                            total: 0,
                        }
                        obj.total = res.data.data.total;
                        obj.data = res.data.data.data;
                        if (res.data.data.data) {
                            res.data.data.data.forEach(item => {
                                if (item.unlockRole == 'house:owner') {
                                    item.unlockRoleStr = '房东';
                                } else if (item.unlockRole == 'tenant:user') {
                                    item.unlockRoleStr = '房客';
                                } else if (item.unlockRole == 'sys:eng') {
                                    item.unlockRoleStr = '工程人员';
                                } else if (item.unlockRole == 'house:manager') {
                                    item.unlockRoleStr = '房管员';
                                } else {
                                    item.unlockRoleStr = '--';
                                }
                                if (item.unlockMethod == 15) {
                                    item.unlockMethodStr = '远程开锁';
                                } else if (item.unlockMethod == 16) {
                                    item.unlockMethodStr = '手动闭锁';
                                } else if (item.unlockMethod == 17) {
                                    item.unlockMethodStr = '单次远程开锁';
                                } else if (item.unlockMethod == 18) {
                                    item.unlockMethodStr = '单次远程闭锁';
                                } else if (item.unlockMethod == 19) {
                                    item.unlockMethodStr = '系统远程开锁';
                                } else if (item.unlockMethod == 20) {
                                    item.unlockMethodStr = '系统远程闭锁';
                                } else {
                                    item.unlockMethodStr = '';
                                }
                                if (item.unlockMethod == 15 || item.unlockMethod == 17 || item.unlockMethod == 19) {
                                    item.unlockStateStr = ['等待开锁', '开锁成功', '开锁失败'][item.unlockState];
                                } else if (item.unlockMethod == 16 || item.unlockMethod == 18 || item.unlockMethod == 20) {
                                    item.unlockStateStr = ['等待闭锁', '闭锁成功', '闭锁失败'][item.unlockState];
                                } else {
                                    item.unlockStateStr = '不支持的指令'
                                };
                                item.unlockStateStr = item.lockName + item.unlockStateStr;
                                item.strategyName = item.strategyName ? item.strategyName : '--';
                                item.accountName = item.accountName ? item.accountName : '--';
                                item.unlockDate = item.unlockDate ? item.unlockDate : '--';
                                
                            });
                        } else {
                            obj.data = []
                        }

                        opt.cb(obj);
                    });
                })
                
            },
            getUrlDto() {
                let dto = {
                    houseId: this.gatewayInfo.houseId,
                }
                let url = 'landlord-service/v1/house/device/gateway/detail'
                if (this.gatewayInfo.innerType == 27) {
                    url = 'landlord-service/v1/house/device/blue-gateway/detail'
                    dto.gatewayIdStr = this.gatewayInfo.deviceToken
                } else {
                    dto.gatewayId = this.gatewayInfo.deviceToken
                }
                return {
                    url: url,
                    dto: dto
                }
            },
            getDetail() {

                const {
                    dto,
                    url
                } = this.getUrlDto(this.gatewayInfo)
                return new Promise ((resolve,reject) => {
                    this.post(url, dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        this.name = res.data.data.name;
                        this.mac = res.data.data.mac;
                        this.wifiName = res.data.data.wifiName ? res.data.data.wifiName : '--';
                        this.houseName = res.data.data.houseName ? res.data.data.houseName : '--';
                        this.location = res.data.data.location;
                        this.onLineStr = ['离线', '在线'][res.data.data.onLine];
                        this.bindTime = res.data.data.bindTime ? res.data.data.bindTime : '--';
                        this.bindUser = res.data.data.bindUser ? res.data.data.bindUser : '--';
                        this.iccid = res.data.data.iccid ? res.data.data.iccid : '--';
                        this.lockNum = res.data.data.locks.length;
                        this.lockList = res.data.data.locks;
                        this.lockList.forEach(item => {
                            item.rssiName = item.rssiName ? item.rssiName : '--';
                            item.lockType = item.lockType ? item.lockType : '--';
                            item.batteryPower = item.batteryPower ? item.batteryPower : '--';
                            item.lastUseDate = item.lastUseDate ? item.lastUseDate : '--';
                            this.macList.push(item.mac)
                        })
                        if (this.gatewayInfo.innerType == 27) {
                            this.innerTypeStr = "WF-L2G";
                            console.log(this.innerTypeStr)
                        } else if (this.gatewayInfo.innerType == 200) {
                            this.innerTypeStr = "G4";
                        } else {
                            this.innerTypeStr = '';
                        }

                    }
                    resolve(res);
                })
                })
            },
            onRefresh() {
                this.lockList = []
                this.logList = []
                const {
                    dto,
                    url
                } = this.getUrlDto()
                this.post(url, dto, {
                    isUseResponse: true
                }).then(res => {
                    if (res.data.code == 0) {
                        this.lockList = res.data.data.locks;
                        this.logList = res.data.data.locks;

                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .baseInfo-title {
        margin-left: 46px;
    }

    .beRelated-row {
        display: flex;
        justify-content: space-between;
    }

    .beRelated-refresh {
        margin-right: 46px;
    }

    .baseInfo-content {
        margin: auto;
        width: 1000px;

        .content-left,
        .content-right {
            display: inline-block;
            width: 500px;
        }

        .content-box {
            margin: 20px 0;
            font-size: 15px;
            display: flex;

            .title {
                display: inline-block;
                padding-right: 40px;
                text-align: right;
                width: 200px;
            }

            .content {
                color: #666;
                display: inline-block;
                width: 260px;
                word-wrap: break-word;
            }

            .describeContent {
                display: inline-block;
                width: 750px;
                word-wrap: break-word;
            }

            .productPicture {
                width: 200px;
                height: 130px;
                margin: 8px;
            }
        }
    }

    .logList {
        height: 500px;
    }
</style>